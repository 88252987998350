import { Card, Table } from 'antd';
import React from 'react';

const Usuarios = () => {
  return (
    <div>
      <Card>Hola</Card>
      <Table></Table>
    </div>
  );
};

export default Usuarios;
