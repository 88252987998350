import React, { useState } from 'react';

import UploadFile from '../components/UploadFile';

const Miembros = () => {
  return (
    <>
      <UploadFile />
    </>
  );
};

export default Miembros;
